<template>
  <div class="fix-page">
    <div class="fix-page-con pr20" v-loading="loading">
      <el-form
        :model="ruleForm"
        class="clearfix"
        label-width="120px"
        ref="ruleForm"
      >
        <el-form-item label="文章主題">
          <el-select
          class="w200 mr10 mb20"
          v-model="ruleForm.rid"
          filterable
          placeholder="請選擇文章主題"
          clearable
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="文章標題">
          <el-input
            :readonly="disable"
            maxlength="30"
            placeholder="请輸入文章標題，20字以內"
            v-model="ruleForm.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="文章標題(English)">
          <el-input
            :readonly="disable"
            placeholder="Please enter the content"
            v-model="ruleForm.title_en"
          ></el-input>
        </el-form-item>
        <el-form-item label="文章概要">
          <el-input
            :readonly="disable"
            placeholder="请輸入文章概要，100字以內"
            type="textarea"
            v-model="ruleForm.abstract"
          ></el-input>
        </el-form-item>
        <el-form-item label="文章概要(English)">
          <el-input
            :readonly="disable"
            placeholder="Please enter the content"
            type="textarea"
            v-model="ruleForm.abstract_en"
          ></el-input>
        </el-form-item>
        <el-form-item label="文章內容">
          <div class="editMember">
            <!-- <p class="editMember-title">{{ readyEditMemberData.name }}</p> -->
            <Tinymce :height="400" v-model="ruleForm.details"></Tinymce>
          </div>
        </el-form-item>
        <el-form-item label="文章內容(English)">
          <div class="editMember">
            <!-- <p class="editMember-title">{{ readyEditMemberData.name }}</p> -->
            <Tinymce :height="400" v-model="ruleForm.details_en"></Tinymce>
          </div>
        </el-form-item>
        <el-form-item label="顯示權重">
          <el-input-number
            v-model="ruleForm.sort"
            label="顯示權重"
          ></el-input-number>
        </el-form-item>
      </el-form>

      <!-- <uEditor
        v-if="!disable"
        :value="ruleForm.content"
        @edit="editCon"
        ref="ueditor"
      ></uEditor>
      <div style="width:700px" class="p20" v-else>
        <el-card>
          <div v-html="ruleForm.content"></div>
        </el-card>
      </div> -->
    </div>
    <div class="fix-page-footer">
      <el-button type="primary" :loading="saving" @click="save" v-if="!disable"
        >保存</el-button
      >
      <el-button @click="close">取消</el-button>
    </div>
  </div>
</template>
<script>
import Tinymce from "@/components/tinymce"
export default {
  components: {
    Tinymce,
  },
  mounted() {
    let type = this.$route.params.type
    this.type = type
    this.getCats()
    if (type == "edit") {
      if (this.$route.query && this.$route.query.id) {
        let id = this.$route.query.id
        this.typeText = "編輯"
        this.getOneData(id)
      } else {
        // 跳到新建
        this.$router.push({
          name: "helpCreate",
          query: {
            id: "",
          },
          params: {
            type: "create",
          },
        })
      }
    } else if (type == "create") {
      // 新建
      this.typeText = "新增"
    } else {
      let id = this.$route.query.id
      this.typeText = "預覽"
      this.getCourseItem(id)
      this.disable = true
    }
  },
  data() {
    return {
      saving: false, //保存防止重复提交
      loading: false,
      disable: false,
      typeText: "",
      type: "edit",
      tiny: {
        value: undefined,
      },
      options: [],
      // 表单
      ruleForm: {
        rid: '',
        title: "",
        title_en: "",
        abstract: "",
        abstract_en: "",
        details: "",
        details_en: "",
        sort: 1,
      },
    }
  },
  methods: {
    save() {
      this.saving = true
      this.saveArticle()
    },
    close() {
      this.$router.push({
        name: "helpList",
        query: {
          ...this.$route.query,
        },
      })
    },
    async getCats() {
      let res = await this.api.help.getArticleCats()
      if (res && res.code == 0) {
        this.options = res.data
      }
    },
    async saveArticle() {
      if (this.type == "create") {
        let res = await this.api.help.createArticle(this.ruleForm)
        if (res && res.code == 0) {
          this.close()
        }
        this.saving = false
      } else {
        let res = await this.api.help.editArticle(this.ruleForm)
        if (res && res.code == 0) {
          this.close()
        }
        this.saving = false
      }
    },
    async getOneData(id) {
      this.loading = true
      let res = await this.api.help.getOneArticle(id)
      this.loading = false
      if (res && res.code == 0) {
        this.ruleForm = { ...this.ruleForm, ...res.data }
      }
    },
  },
}
</script>
<style scoped>
/deep/.el-form-item__label {
  line-height: 20px;
  align-items: center;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}
</style>
