<template>
  <div>
    <editor v-model="myValue" :init="init" :disabled="disabled" @onClick="onClick">
    </editor>
  </div>
</template>

<script>
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import tinymce from "tinymce/tinymce"
import Editor from "@tinymce/tinymce-vue"
import "tinymce/skins/ui/oxide/skin.css"
// import "./lib/langs/zh_CN.js" // 本地汉化
import "tinymce/themes/silver/theme"
import "tinymce/plugins/image"
import "tinymce/plugins/fullscreen"
import "tinymce/plugins/fullpage"
import "tinymce/plugins/hr"
import "tinymce/plugins/preview"
import "tinymce/plugins/code"
import "tinymce/plugins/codesample"
import 'tinymce/plugins/media'
import "tinymce/plugins/table"
import "tinymce/plugins/link"
import "tinymce/plugins/anchor"
import "tinymce/plugins/autolink"
import "tinymce/plugins/lists"
import "tinymce/plugins/contextmenu"
import "tinymce/plugins/wordcount"
import "tinymce/plugins/colorpicker"
import "tinymce/plugins/textcolor"
import "tinymce/icons/default" // 图标 -解决测试环境找不icon问题
export default {
  name: "tinymce",
  components: {
    Editor,
  },
  props: {
    //传入一个value，使组件支持v-model绑定
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plugins: {
      type: [String, Array],
      default:
        // ' anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools insertdatetime link lists   noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'
        "lists image table  wordcount fullscreen fullpage hr preview code codesample",
    },
    menubar: {
      type: [Object, String],
      default: "file edit insert view format table",
      // () => {
      //   return {
      //     file: { title: "File", items: "newdocument" },
      //     edit: {
      //       title: "Edit",
      //       items: "undo redo | cut copy paste pastetext | selectall",
      //     },
      //     insert: { title: "Insert", items: "link media | template hr" },
      //     view: { title: "View", items: "visualaid" },
      //     format: {
      //       title: "Format",
      //       items:
      //         "bold italic underline strikethrough superscript subscript | formats | removeformat",
      //     },
      //     table: {
      //       title: "Table",
      //       items: "inserttable tableprops deletetable | cell row column",
      //     },
      //   }
      // },
    },
    toolbar: {
      type: [String, Array],
      default:
        "bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | blockquote undo redo | removeformat subscript superscript code | hr bullist numlist preview table forecolor backcolor fullscreen image codesample",
    },
    defaultInit: {
      type: Object
    }
  },
  data() {
    console.log("data", this.defaultInit);
    return {
      //初始化配置
      init: this.defaultInit ? this.defaultInit : {
        language_url: "/tinymce/langs/zh_TW.js", // 这里需要单独处理
        language: "zh_TW",
        height: 600,
        width: 900,
        // 皮肤：这里引入的是public下的资源文件
        skin_url: "/tinymce/skins/ui/oxide",
        content_css: "tinymce/skins/content/default/content.css",
        // skin_url: 'tinymce/skins/ui/oxide-dark',//黑色系
        plugins: this.plugins,
        toolbar1: "bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | blockquote undo redo image codesample",
        toolbar2: "removeformat subscript superscript code | hr bullist numlist preview table forecolor backcolor fullscreen",
        toolbar_mode: "wrap",
        menubar: this.menubar,
        branding: false,
        //此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        //如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        // images_upload_handler: (blobInfo, success, failure) => {
        //   const img = "data:image/jpeg;base64," + blobInfo.base64()
        //   success(img)
        // },
        images_upload_handler: (blobInfo, success, failure) => {
          var formData = new FormData()
          formData.append("file", blobInfo.blob(), blobInfo.filename())
          this.api.system.uploadFile(formData).then((res) => {
            if (res && res.code == 0) {
              let imgUrl = this.constants.imgUrl + res.data
              console.log(imgUrl)
              success(imgUrl)
            } else {
              failure("上傳失敗！！！")
            }
          })
        },
        resize: false,
      },
      myValue: this.value,
    }
  },
  mounted() {
    tinymce.init(this.defaultInit ? this.defaultInit : {})
    this.init = this.defaultInit ? this.defaultInit : {}
  },
  methods: {
    //添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    //需要什么事件可以自己增加
    onClick(e) {
      this.$emit("onClick", e, tinymce)
    },
    clear() {
      this.myValue = ""
    },
  },
  watch: {
    defaultInit(newValue) {
      const defaultI = {
        language_url: "/tinymce/langs/zh_TW.js", // 这里需要单独处理
        language: "zh_TW",
        height: 600,
        width: 900,
        // 皮肤：这里引入的是public下的资源文件
        skin_url: "/tinymce/skins/ui/oxide",
        content_css: "tinymce/skins/content/default/content.css",
        // skin_url: 'tinymce/skins/ui/oxide-dark',//黑色系
        plugins: this.plugins,
        toolbar1: "bold italic underline strikethrough link anchor | alignleft aligncenter alignright alignjustify outdent indent | blockquote undo redo image codesample",
        toolbar2: "removeformat subscript superscript code | hr bullist numlist preview table forecolor backcolor fullscreen",
        toolbar_mode: "wrap",
        menubar: this.menubar,
        branding: false,
        //此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        //如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        // images_upload_handler: (blobInfo, success, failure) => {
        //   const img = "data:image/jpeg;base64," + blobInfo.base64()
        //   success(img)
        // },
        images_upload_handler: (blobInfo, success, failure) => {
          var formData = new FormData()
          formData.append("file", blobInfo.blob(), blobInfo.filename())
          this.api.system.uploadFile(formData).then((res) => {
            if (res && res.code == 0) {
              let imgUrl = this.constants.imgUrl + res.data
              console.log(imgUrl)
              success(imgUrl)
            } else {
              failure("上傳失敗！！！")
            }
          })
        },
        resize: false,
      }
      tinymce.init(this.defaultInit ? this.defaultInit : defaultI)
      this.init = this.defaultInit ? this.defaultInit : defaultI
    },
    value(newValue) {
      this.myValue = newValue
    },
    myValue(newValue) {
      this.$emit("input", newValue)
    },
  },
}
</script>
<style >
.tox {
  z-index: 9999;
}
</style>
